import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "radio-buttons"
    }}>{`Radio Buttons`}</h1>
    <p>{`Keep all radio button text concise.`}</p>
    <p>{`Text beside a radio button should be written in sentence case (with no period). Refresher: sentence
case is where the first letter of the phrase is capitalized, and the rest is lowercase.`}</p>
    <p>{`See also: `}<a parentName="p" {...{
        "href": "/guidelines/content/ui-text/checkboxes"
      }}>{`Check Boxes`}</a>{`,
`}<a parentName="p" {...{
        "href": "/guidelines/content/ui-text/dropdown-menus"
      }}>{`Drop-down Menus`}</a>{`, and
`}<a parentName="p" {...{
        "href": "/guidelines/content/ui-text/instructional-text"
      }}>{`Instructional Text`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      